body {
  margin: 0;
  padding: 0;
}

.scroll_invisible {
 &::-webkit-scrollbar{
  width: 0;

}
 &::-webkit-scrollbar-track{
  width: 0;

}
 &::-webkit-scrollbar-thumb{
  width: 0;
}
}

:root {
  --main-black-color: #3A3F48;
  --main-purple-color: #538DFE;
  --main-purple-hover-color: #E7EEF9;
  --main-bg-color: rgba(244, 246, 249, 1);
  --main-light-text-color: rgba(0, 0, 0, 0.60);
  --main-divider-color: rgba(0, 0, 0, 0.40);
  --main-grey-color: rgba(0, 0, 0, 0.40);
  --form-max-width: 740px;
  --background-color: #F4F6F9;
  --table-th-bg: #F7F0FF;
  --button-background-color: linear-gradient(90deg, #90CAF9 0%, #3378FF 100%, #047EDF 100%);
}