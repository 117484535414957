.swiper {
    width: 100%;
    margin-bottom: 1.5rem;
}

.swiper-slide {
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.swiper-slide {
    width: 373px;
}

.swiper-slide:last-child {
    margin-right: 2px !important;
}
